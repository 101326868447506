
import './App.css';

import Home from './pages/home';
import MyMassiveLinks from './pages/myMassiveLinks';

import ErrorPage from './pages/error'

import {
  BrowserRouter ,
  Routes,
  Route
} from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
    <Routes>

        <Route index element={<Home />} />
        <Route path='links' element={<MyMassiveLinks/>} />
        <Route path='*' element={<ErrorPage />} />

    </Routes>
  </BrowserRouter>
  )
}



export default App;
