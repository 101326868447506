import { useNavigate, useLocation } from "react-router-dom";


function Home() {
	const navigate = useNavigate();

	const search = useLocation().search;
	const ids = new URLSearchParams(search).get('ids');
	const token = new URLSearchParams(search).get('token');
	const documentType = new URLSearchParams(search).get('documentType')
	const showColorLabel = new URLSearchParams(search).get('accessColorLabel');

  	function CraftMyPDfPopin() {
    	navigate({
			pathname:"/links",
			search:'?locale=fr_FR&ids=' + ids + '&token=' + token
		});
  	}

	
	return (
		<div >
			<div >
				<p>Ceci est la page Home</p>
			</div>
			<button type="button" onClick={CraftMyPDfPopin}>
		      Go Linking
    		</button>
		</div>
	)
}

export default Home;